@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.section__cta {
  display: flex;
  flex-direction: row;
  padding-block: 5rem;
}
.section__cta p {
  margin-block: 3rem;
}

.section__cta-start {
  width: 60%;
  margin-right: 10%;
}

.section__cta-end {
  width: 30%;
}
